body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1 {
  color: #1c1b20;
  margin: 5px 0 25px;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  font-weight: 700;
  text-transform: capitalize;
}
h1 svg {
  /* vertical-align: middle; */
  margin-right: 10px;
}

h2,
h3 {
  font-weight: 700;
  letter-spacing: 0.2px;
}
h4 {
  font-weight: 550;
}
ul li,
ul li a,
textarea {
  font-weight: 450;
}

.MuiAvatar-root.MuiAvatar-colorDefault {
  background-color: #e1e5ef;
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
:hover::-webkit-scrollbar-thumb {
  display: block;
}
::-webkit-scrollbar-thumb {
  background: #98989c;
  background: #d2d2d2;
  border-radius: 8px;
  display: none;
}
input::placeholder {
  color: #b2b2b2;
  font-weight: 400;
}
.mainInnerStyles::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: transparent;
}

.orderStatus {
  padding: 4px 15px 3px;
  border-radius: 20px;
  color: #fff;
  opacity: 0.8;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}
.mainInnerView {
  padding: 20px;
}
.rightInnerView {
  padding: 20px;
}

.pac-container {
  z-index: 9999 !important;
}

.listPaper {
  background: #fff;
  border: 1px solid #e8e8e8;
  padding: 20px;
}

hr {
  border: 0px;
  border-bottom: 1px solid #e2e2e2;
}
a {
  color: #2d4961;
  text-decoration: none;
}
.gm-style .gm-style-iw {
  display: flex;
  align-items: flex-start;
  max-width: 300px !important;
  font-size: 13px;
  line-height: 18px;
}
.gm-style .gm-style-iw-c .infowindowstyle {
  /* width: 92%; */
  margin: 0 10px 0 0;
}

.gm-style .gm-style-iw-c .gm-ui-hover-effect {
  top: -1px !important;
  right: 3px !important;
  position: relative !important;
}

#mapView .gm-style {
  background: #151515;
}
#mapView .gm-style .gm-style-iw {
  display: flex;
  align-items: flex-start;
  max-width: 300px !important;
  font-size: 13px;
  line-height: 18px;
  background: #282a2d;
  border: 1px solid #000;
  padding: 7px 10px !important;
}
#mapView .gm-style .gm-style-iw-c .infowindowstyle {
  /* width: 92%; */
  margin: 0 10px 0 0;
}
.gm-style .gm-style-iw-t::after,
,
.gm-style .gm-style-iw-tc::after {
  background: #282a2d;
  box-shadow: none;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  /* top: -2px; */
}
#mapView .gm-style .gm-style-iw-c .gm-ui-hover-effect {
  top: -1px !important;
  right: 0px !important;
  position: relative !important;
  border-radius: 20px;
  background: #000 !important;
  margin-left: 10px !important;
  width: 25px !important;
  height: 25px !important;
}
#mapView .gm-ui-hover-effect > span {
  background-color: #bbb;
  margin: 5px !important;
  text-align: center !important;
}

#mapView .gm-style .gm-style-iw-d {
  overflow: auto !important;
}
#mapView .gm-style .gm-style-iw-d h4 {
  color: #aaa;
  margin: 2px 0 !important;
}
#mapView .gm-style .gm-style-iw-d p {
  color: #777;
}
#mapView .gm-style-cc div {
  /* display: none; */
  background: #444;
}

#mapView .gm-style a img {
  opacity: 0.2;
}

.modalRight {
  position: absolute;
  right: 0;
  height: 100vh;
  border-radius: 0px !important;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  overflow: overlay;
  opacity: 0;
}
.modalRight.fade {
  opacity: 1;
  right: -400px;
  transition: none !important;
  -webkit-animation: slideRight 0.5s forwards;
  animation: slideRight 0.5s forwards;
}
.modalRight.fadeM {
  opacity: 1;
  right: -400px;
  transition: none !important;
  -webkit-animation: slideRightMax 0.5s forwards;
  animation: slideRightMax 0.5s forwards;
}
.modalRight.fadeMW {
  opacity: 1;
  right: -650px;
  transition: none !important;
  -webkit-animation: slideRightMaxWidth 0.5s forwards;
  animation: slideRightMaxWidth 0.5s forwards;
}

.modalRight .react-datepicker-wrapper {
  display: block;
}
.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 999;
  left: -70px !important;
}
.modalRight .react-datepicker-popper {
  left: -30px !important;
}

.react-datepicker__week .react-datepicker__day--selected,
.react-datepicker__week .react-datepicker__day--in-selecting-range,
.react-datepicker__week .react-datepicker__day--in-range,
.react-datepicker__week .react-datepicker__month-text--selected,
.react-datepicker__week .react-datepicker__month-text--in-selecting-range,
.react-datepicker__week .react-datepicker__month-text--in-range,
.react-datepicker__week .react-datepicker__quarter-text--selected,
.react-datepicker__week .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__week .react-datepicker__day--selected:hover,
.react-datepicker__week .react-datepicker__day--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__day--in-range:hover,
.react-datepicker__week .react-datepicker__month-text--selected:hover,
.react-datepicker__week .react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__month-text--in-range:hover,
.react-datepicker__week .react-datepicker__quarter-text--selected:hover,
.react-datepicker__week
  .react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__quarter-text--in-range:hover,
.react-datepicker__week .react-datepicker__year-text--selected:hover,
.react-datepicker__week .react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background: #f7b500!important;
}

.map-container .map {
  position: relative !important;
}
.modalRight h3 {
  font-size: 20px;
  margin: 20px 15px;
}
.rc-time-picker-panel {
  z-index: 99999 !important;
}
.Snackbar_snackbar-wrapper__ocbPJ {
  z-index: 99999 !important;
}
.mainInnerView .MuiTableContainer-root::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}
.slide-up {
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.slide-down {
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999 !important;
}

/* @keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
} */

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slide-down {
  0% {
    opacity: 0;
    -moz-transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes slide-width {
  0% {
    width: 0;
    opacity: 0;
    right: 0%;
    transform: translateX(0%) scaleX(0.4);
    transform-origin: right;
  }
  100% {
    width: 400px;
    opacity: 1;
    /* transform: translateY(0%); */
    transform: translateX(-400px) scaleX(0.4);
    transform-origin: right;
  }
}

@keyframes slideRight {
  0% {
    right: -400px;
  }
  100% {
    right: 0px;
  }
}
@keyframes slideRightMax {
  0% {
    right: -500px;
  }
  100% {
    right: 0px;
  }
}
@keyframes slideRightMaxWidth {
  0% {
    right: -650px;
  }
  100% {
    right: 0px;
  }
}
@keyframes slideHight {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 30px; /* your line height here */
    opacity: 1;
  }
}

@keyframes slideMaxHight {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 60px; /* your line height here */
    opacity: 1;
  }
}
