.leaflet-container{
    height:calc(100vh - 320px)
}

.leaflet-div-icon {
    background: #fff;
    border: 1px solid #2d4961;
    height: 12px!important;
    width: 12px!important;
    margin-left: -7px!important;
    margin-top: -7px!important;
}

.leaflet-container  .leaflet-control-geosearch form input{height:30px}
.leaflet-container  .leaflet-control-geosearch form{left:30px;padding-left: 0;box-shadow:none;border:2px solid #ccc;top:-2px;border-left:0px}
.leaflet-geosearch-button.active .leaflet-bar-part{width:30px;}
.leaflet-container.leaflet-touch .leaflet-control-layers, .leaflet-container.leaflet-touch .leaflet-bar{border:2px solid #ccc}
.leaflet-container .leaflet-control-geosearch a.leaflet-bar-part:before{top: 18px;
    left: 15px;
    width: 6px;}
   .leaflet-container .leaflet-control-geosearch a.leaflet-bar-part:after{height: 10px;
    width: 10px;}