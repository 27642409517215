.Sidebar {
  width: 18%;
  min-width: 165px;
  background: #1c1b20;
  height: 100vh;
  overflow: overlay;
  border-right: 1px solid #444;
}
.App-header {
  display: inline-flex;
  /* width:100%;  */
  padding: 22px 17px;
}
.App-header img {
  width: 38px;
  padding: 5px;
  height: 38px;
  background: #fff;
  margin-right: 15px;
  border-radius: 50%;
}
.App-header h3 {
  margin: 4px 0;
  font-weight: 500;
  color: #fff;
  font-size: 17.5px;
}
.App-header p {
  color: #ccc;
  margin: 0px;
  font-size: 15px;
}
.Sidebar h4 {
  color: #616161;
  font-weight: 500;
  padding: 0 20px;
  margin: 15px 0;
}
.Sidebar ul {
  list-style-type: none;
  margin: 0 0 15px;
  padding: 0 10px;
}
.Sidebar ul li {
  display: flex;
  margin: 2px 0;
}
.Sidebar ul li a {
  color: #dddddd;
  font-size: 14px;
  padding: 7px 8px 9px;
  width: 90%;
  display: inline-block;
  text-decoration: none;
  font-weight: 550;
  letter-spacing: -0.2px;
}
.Sidebar ul li.active {
  background: #f7b500;
}
.Sidebar ul li.active a {
  color: #fff;
}
.Sidebar ul li a .icon {
  margin-right: 15px;
}
.Sidebar ul .icon {
  margin-right: 10px;
}
.Sidebar ul li:hover {
  background: #3a393e;
  border-radius: 4px;
}
.Sidebar .MuiSvgIcon-fontSizeSmall {
  color: #777779;
  margin-right: 10px;
  font-size: 1.2rem;
  vertical-align: bottom;
}

.Sidebar hr {
  border: 0px;
  border-bottom: 1px solid #444;
}

.Sidebar-footer {
  position: fixed;
  width: 18%;
  min-width: 165px;
  bottom: 0px;
}
.Sidebar-footer p {
  text-align: center;
  color: #616161;
  font-size: 14.5px;
}
.Sidebar span .MuiSvgIcon-root {
  float: right;
  color: #888888;
  font-size: 1.3rem;
  padding: 9px 5px 7px 0;
}

.Sidebar .MuiTreeView-root {
  padding: 7px 8px 9px;
  width: 90%;
  margin: 0;
  display: inherit;
}
.Sidebar .MuiTreeItem-root {
  display: block;
  width: 100%;
  margin: 0;
}
.Sidebar .MuiTreeItem-iconContainer {
  width: 0px;
  margin-right: 0px;
}
.Sidebar .MuiTreeItem-label {
  color: #dddddd;
}
.Sidebar
  .MuiTreeItem-root.Mui-selected
  > .MuiTreeItem-content
  .MuiTreeItem-label,
.Sidebar
  .MuiTreeItem-root.Mui-selected
  > .MuiTreeItem-content
  .MuiTreeItem-label:hover,
.Sidebar
  .MuiTreeItem-root.Mui-selected:focus
  > .MuiTreeItem-content
  .MuiTreeItem-label {
  background-color: transparent;
  color: #fff;
}
.Sidebar .MuiTreeItem-group {
  margin: 0;
  padding: 0;
}
.Sidebar .MuiCollapse-wrapperInner a {
  padding: 5px 0;
}
.Sidebar .MuiCollapse-wrapperInner a:last-child {
  padding-bottom: 0px;
}
